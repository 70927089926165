<template>
    <div class="init">
        <el-form :model="fromValue" :rules="rules" ref='form_' label-position="top" inline>
                <el-form-item :label="$t('chain.text2')" prop="chain">
                    <el-input v-model="fromValue.chain" :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('chain.text3')" prop="baseCoin">
                    <el-input v-model="fromValue.baseCoin" :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('chain.text4')" prop="fastConfirms">
                    <el-input v-model="fromValue.fastConfirms" :placeholder="$t('inputTit.shuru')" />
                </el-form-item>
                <el-form-item :label="$t('chain.text5')" prop="safeConfirms">
                    <el-input v-model="fromValue.safeConfirms" :placeholder="$t('inputTit.shuru')"  />
                </el-form-item>
                <el-form-item :label="$t('chain.text6')" prop="gasTrackerApi">
                    <el-input v-model="fromValue.gasTrackerApi" :placeholder="$t('inputTit.shuru')" />
                </el-form-item>
                <el-form-item :label="$t('chain.text7')" prop="chainList">
                    <el-select v-model="fromValue.chainList" :placeholder="$t('inputTit.xuanze')">
                        <el-option v-for="item in chainListList" :key="item.label" :label="item.label" :value="parseInt(item.value)"/>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('chain.text8')" prop="hasToken">
                    <el-radio-group v-model="fromValue.hasToken">
                        <el-radio v-for="items in hasToken" :label="items.value" size="large" border :key="items.value">{{items.label}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { getDataStyle } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        chainId:'',
        chain: '',baseCoin:'',fastConfirms:'',safeConfirms:'',
        gasTrackerApi:'',chainList:'',hasToken:'0',
    })
    const chainListList = ref([]);getDataStyle('chain_list_type',(e)=>{chainListList.value = e})
    const hasToken = ref([]);getDataStyle('has_token',(e)=>{hasToken.value = e})

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun'])
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.configId
                    Api_.addCoinObj(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateCoinObj(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(JSON.stringify(obj) !== "{}"){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            fromValue.hasToken = fromValue.hasToken.toString()
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_,completeFun})
</script>